import React, {createContext, useEffect, useState} from "react";
import {database} from "../../firebase";
import ClinicCard from "../clinic-card/clinic-card";
import {Clinic, Colors} from "../../models/clinic.model";
import {ClinicListWrapper} from "./style";
import {onValue, ref} from "@firebase/database";
import AboutClinicModal from "../modals/about-clinic/about-clinic.modal";
import InfiniteScroll from "react-infinite-scroll-component";
import {isMobile} from "react-device-detect";

interface ClinicListProps {
  clinics: Clinic[]
}

export const ClinicListContext = createContext({} as {setSelectedClinicContext: any});

const ClinicList: React.FC<ClinicListProps> = (props) => {
  const {clinics} = props;
  const [colors, setColors] = useState<Colors>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [selectedClinic, setSelectedClinic] = useState<Clinic | null>();
  const [clinicsForLoad, setClinicsForLoad] = useState<Clinic[]>();
  const [min, setMin] = useState<number>(9);
  const [max, setMax] = useState<number>(18);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(()=>{
    const starCountRef = ref(database, 'Colors');

    onValue(starCountRef, (snapshot: { val: () => any; }) => {
      const data = snapshot.val();
      setColors(data)
    });
  }, [])

  useEffect(() => {
    if (selectedClinic) {
      handleOpenModal();
    }
  },[selectedClinic])

  useEffect(() => {
    setClinicsForLoad(clinics.slice(0,9));
    setHasMore(true);
    setMin(9);
    setMax(18);
  }, [clinics])

  const handleOpenModal = () => {
    setIsOpenModal(true);
  }

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setSelectedClinic(null);
  }

  const fetchData = () => {
    if (clinicsForLoad && clinicsForLoad.length < clinics.length) {
      setTimeout(() => {
        setClinicsForLoad(clinicsForLoad.concat(clinics.slice(min,max)));
        setMin(min+8);
        setMax(max+8);
      }, 500);
    } else {
      setHasMore(false)
    }
  }

  const setSelectedClinicContext = (id: string) => {
    handleCloseModal()
    const clinic = clinics.find(clinic => clinic.id === id);
    setTimeout(() => {
      setSelectedClinic(clinic);
    },300)
  }

  return (
    <ClinicListContext.Provider value={{setSelectedClinicContext}}>
      <ClinicListWrapper className={isMobile ? 'mobile-view' : ''}>
        {clinicsForLoad && colors && <InfiniteScroll
            dataLength={clinicsForLoad.length}
            next={fetchData}
            scrollThreshold={0.8}
            hasMore={hasMore}
            height={isMobile ? 'calc(100vh - 145px)' : 'calc(100vh - 183px)'}
            className="infinityContainer"
            loader={<h4>Loading...</h4>}
          >
            {clinicsForLoad && clinicsForLoad.map((clinic: Clinic, index) => <ClinicCard key={index} clinic={clinic} colors={colors} setSelectedClinic={setSelectedClinic} />)}
          </InfiniteScroll> }

        <AboutClinicModal isOpen={isOpenModal} handleCloseModal={handleCloseModal} clinic={selectedClinic as Clinic} />
      </ClinicListWrapper>
    </ClinicListContext.Provider>
  )
}

export default ClinicList;
