import styled from "styled-components";

export const TopBarWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #D1D5DB;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  
  &.mobile-view {
    padding: 0 20px;
  }
`

export const SelectWrapper = styled.div`
  width: fit-content;
  
  &.mobile-view {
    width: 100%;
  }
`

export const Label = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #111827;
  display: flex;
  align-items: center;
  gap: 15px;
  
  &.mobile-view {
    width: 50%;
    padding-left: 20px;
  }
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 24px;
  color: #111827;
`

export const FiltersButton = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
  display: flex;
  gap: 20px;
  width: 50%;
  border-right: 1px solid #D1D5DB;
  padding: 16px 0;
  
  .filters-check-icon {
    width: 22px;
    margin-left: -10px;
  }
`
