import React from "react";
import {Review} from "../../../../../models/clinic.model";
import {Inner, Name, Rating, ReviewCard, ReviewDate, ReviewText, TopRow} from "./style";
import userIcon from "../../../../../assets/images/user-icon.svg";
import { Icon } from "../../style";
import moment from "moment/moment";

interface CoachReviewProps {
  review: Review
}

const CoachReview: React.FC<CoachReviewProps> = (props) => {
  const {review} = props;

  const getDate = () => {
    return moment(String(review.date.replace(/\./g, "/"))).format("MMM, YYYY")
  }

  return (
    <ReviewCard  width={100/5*(+review.points)}>
      <Inner>
        <TopRow>
          <Icon src={userIcon}/>
          <Name>
            {review.name}
          </Name>
          <ReviewDate>
            { getDate() }
          </ReviewDate>
          <div className="star">
            <div className="rating">
              <span>&#x2605;</span>
            </div>
          </div>
          <Rating>
            {review.points}
          </Rating>
        </TopRow>
        <ReviewText>
          {review.text}
        </ReviewText>
      </Inner>
    </ReviewCard>
  )
}

export default CoachReview
