import React, {useContext} from "react";
import {Clinic} from "../../../../../models/clinic.model";
import {ButtonSmall, ClinicCardWrapper, Inner, List, ListItem, Name} from "./style";
import {Icon} from "../../../../header/style";
import calendarIcon from "../../../../../assets/images/calendar-icon.svg";
import moment from "moment";
import {DateInfo} from "../../../../clinic-card/style";
import {Button} from "../../style";
import {ClinicListContext} from "../../../../clinik-list/clinic-list";

interface ClinicCardSmallProp {
  clinic: Clinic
}

const ClinicCardSmall: React.FC<ClinicCardSmallProp> = (props) => {
  const {clinic} = props;
  const context: {setSelectedClinicContext: any} = useContext(ClinicListContext);

  const getStartDate = (): string => {
    return new Date(clinic.StartTime.seconds*1000).toString()
  }

  const setClinic = () => {
    context.setSelectedClinicContext(clinic.id)
  }

  return (
    <ClinicCardWrapper>
      <Inner>
        <Name>{clinic.ClinicName?.id}</Name>
        <DateInfo>
          <Icon src={calendarIcon} />
          {moment(getStartDate()).format("dddd, MMM Do")}
        </DateInfo>
        <List>
          <ListItem>{clinic.SkillLevel}</ListItem>
          <ListItem>{clinic.Spots} spots left</ListItem>
        </List>

        <ButtonSmall onClick={setClinic}>Open</ButtonSmall>
      </Inner>

    </ClinicCardWrapper>
  )
}

export default ClinicCardSmall;
