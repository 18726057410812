export const config = {
  GOOGLE_MAP_KEY: 'AIzaSyCfENSlwygdTOiOX1-DnWzGMiB99z6wMco',
  LS_CITY_KEY: "selectedCity",
  DEFAULT_CITY: "Toronto",
  DEFAULT_TEXT_COLOR: {R: '255', G: '255', B: '255'},
  SELECT_THEME_CONFIG : {
    color: {
      primary: "#4B5563"
    },
    select: {
      css: "font-size: 16px;line-height: 24px; font-weight: 400;text-align: left;",
      margin:"0!important"
    },
    control: {
      borderRadius: "6px",
      padding: "6px 11px"
    },
    input: {
      css: "margin: 0; color: black; font-weight: 500;",
    }
  },
  MAILCHIMP_URL: 'https://putonaclinic.us21.list-manage.com/subscribe/post?u=c949d4c86f567badce7a7db4b&amp;id=8e759a86c6&amp;f_id=00655ee1f0'
}
