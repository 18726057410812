import styled from "styled-components";

export const ClinicListWrapper = styled.div`
  width: 100%;
  .infinityContainer {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 30px;
    row-gap: 40px;
    padding: 40px 20px;
    box-sizing: border-box;
    
    @media (min-width: 575px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 800px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (min-width: 991px) {
    width: 79%;
  }
  
  &.mobile-view {
    @media (min-width: 991px) {
      width: 100%;
    }
  }
`
