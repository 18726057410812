import React, {useState} from "react";
import {DatesContainer, ShowControl, Wrapper } from "./style";
import moment from "moment";
import {Dot, Space} from "../modals/about-clinic/style";
import {isDesktop, isMobile} from "react-device-detect";

interface MoreDatesProps {
  listDates: string[],
  startTime: string,
  endTime: string
}

const MoreDates: React.FC<MoreDatesProps> = (props) => {
  const {listDates, startTime, endTime} = props;
  const [isShow, setShow] = useState<boolean>(isMobile ? true : false);

  const dateItem = (item: string, index: number) => {
    return <span key={index}>
      {moment(item.replace(/\./g, "/")).format("dddd, MMM Do")}
      <Dot/>
      {moment(startTime).format("h:mmA")}
      <Space>-</Space>
      {moment(endTime).format("h:mmA")}
    </span>
  }

  return (
    <Wrapper>
      { isDesktop && <ShowControl>
        {!isShow && <span className={'control'} onClick={() => setShow(true)}>More dates</span>}
        {isShow && <span className={'control'} onClick={() => setShow(false)}>Show less</span>}
      </ShowControl> }

      {
        isShow &&
        <DatesContainer className={isMobile ? 'mobile-view' : ''}>
          {isShow && listDates.map((i, index) => dateItem(i, index))}
        </DatesContainer>
      }

    </Wrapper>
  )
}

export default MoreDates;
