import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: flex-start;
  z-index: 99999;

  input {
    color: #FFF;
    font-size: 18px;
    padding: 9px 13px;
    font-weight: 400;
    line-height: 20px;
    border-radius: 6px;
    border: 1px solid #FFF;
    background: rgba(255, 255, 255, 0.20);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(12.5px);
    max-width: 440px;
    width: 100%;
    margin-top: 17px;
    box-sizing: border-box;
    
    &::placeholder {
      color: #FFF;
    }
    
    &:focus-visible {
      outline: none;
    }
  }
  
  &.mobile-view {
    align-items: center;
  
    input {
      max-width: unset;
    }
  }
`

export const Button = styled.a`
  background: #E9072B;
  border-radius: 8px;
  padding: 12px 40px;
  width: fit-content;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
  
  &.mobile-view {
    width: 80%;
  }

  &:hover {
    opacity: 0.8;
  }
`
