import styled from 'styled-components';

export const Wrapper = styled.div<{ image?: string, fullHeight: boolean, headerHeight: string }>`
  background-image: url(${props => props.image});
  height: ${props => props.fullHeight ? '100vh' : props.headerHeight+'px'};
  background-repeat: no-repeat;
  background-size: cover;
  transition: ${props => props.fullHeight ? 'none' : 'height 1s ease'}; ;
  position: relative;
`

export const Top = styled.div<{headerHeight: string}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.headerHeight || "50"}px;
  padding: 0 20px;
`
export const Logo = styled.img`
  cursor: pointer;
`

export const Icon = styled.img`
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
  
  &.mobile-view {
    display: none;
    position: absolute;
    top: 49px;
    left: 0;
    right: 0;
    width: 100%;
    background: #001628;
    flex-direction: column;
    padding: 30px 0;
    gap: 35px;
    z-index: 99;
    
    &.show-menu {
      display: flex;
    }
  }
`

export const LinkExternal = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
  
  &:hover {
    opacity: 0.7;
  }
`

export const Button = styled.a`
  background: #E9072B;
  border-radius: 8px;
  padding: 8px 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  
  &.transform {
    background: #fff;
    color: #111827;
  }

  &:hover {
    opacity: 0.8;
  }
`

export const ScrollDown = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  
  span {
    color: #FFF;
    font-size: 22px;
    font-style: normal;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    animation: pulse 3s infinite;
    cursor: pointer;
  }
  
  img {
    width: 28px;
  }
  
  &.mobile-view {
    bottom: 120px;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.95);
    }
  }
`

export const MailChimpContent = styled.div`
  color: #FFF;
  width: 43%;
  margin-left: auto;
  padding-right: 60px;
  position: absolute;
  right: 0;
  top: calc(50% - 150px);
  box-sizing: border-box;

  &.mobile-view {
    width: 100%;
    padding: 0 20px;
    position: relative;
    right: unset;
    top: 25%;
    
    .title {
      text-align: center;
      font-size: 30px;
    }

    .subtitle {
      text-align: center;
      font-size: 18px;
    }
  }
  
  .title {
    text-align: left;
    font-size: 65px;
    font-weight: 700;
    padding-bottom: 10px;
  }
  
  .subtitle {
    text-align: left;
    font-size: 24px;
    font-weight: 500;
  }
`

export const SubscribeLine = styled.div`
  display: flex;
  height: 40px;
  padding: 9px 0px;
  justify-content: center;
  align-items: center;
  background: #001628;
  box-sizing: border-box;
  
  span {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
  }
`
