import React, {CSSProperties, useCallback, useEffect, useState} from "react";
import Modal from "react-modal";
import {Option} from "../../../pages/home/Home";
import {config} from "../../../config";
import {Button, ModalWrapper, SubTitle, Title} from "./style";
import {isMobile} from "react-device-detect";

Modal.setAppElement('#root');

const modalStyles: CSSProperties = {
  width: isMobile ? '95%' :'550px',
  maxHeight: '90%',
  maxWidth: '1045px',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '10px',
  boxSizing: 'border-box',
  padding: '0'
};

const styles = {
  content: modalStyles
}

interface ModalProps {
  isOpen: boolean,
  handleCloseModal: () => void,
  cities?: Option[],
  setCurrentCity: any,
  setSelectedCity: any
}


const LocationNotFound: React.FC<ModalProps> = (props) => {
  const {isOpen, handleCloseModal, cities, setCurrentCity, setSelectedCity} = props;
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const handleConfirm = () => {
    localStorage.setItem(config.LS_CITY_KEY, config.DEFAULT_CITY as string);
    setSelectedCity(config.DEFAULT_CITY);
    setCurrentCity(cities?.find(i => i.value.toString().toLowerCase() === config.DEFAULT_CITY.toLowerCase()));
    closeModal();
  }

  useEffect(() => {
    if (isOpen) {
      setModalIsOpen(true);
    }
  }, [isOpen])

  const closeModal = () => {
    setModalIsOpen(false);
    handleCloseModal();
  }

  return(
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Clinic Modal"
      shouldCloseOnOverlayClick={false}
      style={styles}
    >
      <ModalWrapper>
        <SubTitle>
          Clinic is only available in select cities. If you would like to see Clinics in your city or region email us at <a
          href="mailto:contactus@putonaclinic.com">contactus@putonaclinic.com</a>
        </SubTitle>

        <Button onClick={handleConfirm}>
          Ok
        </Button>
      </ModalWrapper>
    </Modal>
  )
}

export default LocationNotFound;
