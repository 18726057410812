import styled from "styled-components";
import {Button} from "../../style";

export const ClinicCardWrapper = styled.div`
  padding: 0 10px 10px 0;
  
  @media (min-width: 991px) {
    padding: 10px 10px 15px;
  }
`

export const Inner = styled.div`
  background: #F5F5F5;
  padding: 10px 10px 15px;
  border-radius: 5px;
`

export const Name = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #111827;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const List = styled.div`
  padding: 0 0 10px;
`

export const ListItem = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
  display: flex;
  gap: 7px;
  text-transform: capitalize;
  
  &:before {
    content: "•";
    display: block;
    width: 4px;
    height: 4px;
    padding-left: 3px;
  }
`

export const ButtonSmall = styled(Button)`
  font-size: 16px;
  line-height: 20px;
  display: block;
`
