import styled from "styled-components";
import { Wrapper } from "../about-clinic/style";

export const ModalWrapper = styled(Wrapper)`
  flex-direction: column;
  align-items: center;
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 24px;
  color: #111827;
  padding-bottom: 15px;
  text-align: center;
`

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #1F2937;
  padding-bottom: 30px;
`

export const Button = styled.button`
  background: #E9072B;
  border-radius: 8px;
  padding: 8px 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  border: none;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.7;
    cursor: inherit;
  }
`
