import styled from "styled-components";
import {Color} from "../../models/clinic.model";

export const ClinicCardContainer = styled.div`
  cursor: pointer;
`

export const TopContainer = styled.div<{ backgroundImage?: string, backgroundColor: Color }>`
  width: 100%;
  background: url("${props => props.backgroundImage ? props.backgroundImage : ''}");
  background-color: rgb(${props => props.backgroundColor?.R || 255}, ${props => props.backgroundColor?.G || 255}, ${props => props.backgroundColor?.B || 255});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  display: block;
  height: 208px;
  position: relative;
  border-radius: 8px;
  
  @media (min-width: 991px) {
    height: 14vw;
  }
`

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 11px;
`
export const ClinicName = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #111827;
  width: 60%;
  text-align: left;
`

export const Price = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #111827;
  width: 40%;
  text-align: right;
`

export const Duration = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1F2937;
`

export const Spots = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: #6B99D1;
  border-radius: 4px;
  padding: 2px 12px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`

export const Years = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #111827;
`

export const SkillLevel = styled.div<{ textColor: Color }>`
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 4px;
  padding: 2px 10px 2px 8px;
  color: rgb(${props => props.textColor?.R}, ${props => props.textColor?.G}, ${props => props.textColor?.B});
  max-width: 107px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  width: max-content;
  display: flex;
  align-items: center;
  
  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgb(${props => props.textColor?.R}, ${props => props.textColor?.G}, ${props => props.textColor?.B});
    margin-right: 7px;
    flex: none;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CoachName = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1F2937;
  padding-top: 5px;
`

export const LocationName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #6B7280;
`

export const DateInfo = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #587591;
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: left;
`

export const TimeInfo = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #587591;
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: left;
`

export const Icon = styled.img`
`
