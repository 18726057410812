import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCFzNnm_k3-lvo2vsad9JgcJ7iRAH09ZxM",
  authDomain: "clinic-f00c5.firebaseapp.com",
  databaseURL: "https://clinic-f00c5-default-rtdb.firebaseio.com",
  projectId: "clinic-f00c5",
  storageBucket: "clinic-f00c5.appspot.com",
  messagingSenderId: "983261140525",
  appId: "1:983261140525:web:bf394a198470f1d1a9caa4",
  measurementId: "G-2ZXDJ59G1H"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
const db = firebaseApp.firestore();
const database = getDatabase();

export {db, storage, database}
