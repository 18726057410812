import React, {useCallback, useEffect, useState} from "react";
import {FiltersButton, Label, SelectWrapper, Title, TopBarWrapper} from "./style";
import {Select} from "react-functional-select";
import {Option} from "../../pages/home/Home";
import {config} from "../../config";
import LocationNotFound from "../modals/location-not-found/location-not-found";
import {isDesktop, isMobile} from "react-device-detect";
import FiltersIcon from "../../assets/images/filters-icon.svg"
import FiltersCheckIcon from "../../assets/images/check-circle-icon.svg"
import {Icon} from "../header/style";
import {onValue, ref} from "@firebase/database";
import {database} from "../../firebase";
import {useJsApiLoader} from "@react-google-maps/api";

interface TopBarProps {
  setSelectedCity: any,
  toggleFilters: () => void,
  isFilterSelected: boolean
}

const TopBar: React.FC<TopBarProps> = (props) => {
  const {setSelectedCity, toggleFilters, isFilterSelected} = props;
  const [currentCity, setCurrentCity] = useState<Option | null>(null);
  const [cities, setCities] = useState<Option []>();
  const [citiesData, setCitiesData] = useState<any>();
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const getOptionLabel = useCallback((opt: Option): string => `${opt.label}`, []);
  const getOptionValue = useCallback((opt: Option): number | string => opt.value, []);
  const onCityChange = useCallback((opt: Option | null): void => onCitySelect(opt), []);
  const [isOpenLNF, setIsOpenLNF] = useState<boolean>(false);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [isLocationHandled, setIsLocationHandled] = useState<boolean>(false);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.GOOGLE_MAP_KEY
  })

  const onCitySelect = (opt: Option | null) => {
    setSelectedCity(opt?.value);
    setCurrentCity(opt);
    localStorage.setItem(config.LS_CITY_KEY, opt?.value as string);
  }

  useEffect(()=>{
    const starCountRef = ref(database, 'Cities');

    onValue(starCountRef, (snapshot: { val: () => any; }) => {
      const data = snapshot.val();
      const cities: Option[] = [];
      setCitiesData(data);
      Object.keys(data).forEach(k => {
        cities.push({
          value: k,
          label: k
        })
      })
      setCities(cities)
    });
  }, [])

  useEffect(() => {
    if (cities && isLoaded) {
      const city = localStorage.getItem(config.LS_CITY_KEY);
      const selectedCity = city ? cities?.find(i => i.value === city) || null : null;

      if (selectedCity) {
        setSelectedCity(selectedCity?.value);
        setCurrentCity(selectedCity);
      } else {
        handleShareLocation();
      }
    }
  }, [cities, isLoaded]);

  const handleShareLocation = () => {
    setIsLocationHandled(true);
    const options = {
      enableHighAccuracy: true,
    };
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
  }


  const successCallback = async (position: any) => {
    const matrix = new window.google.maps.DistanceMatrixService();
    await cities?.some((city, index) => {
      const cityData = citiesData[city.value];
      const citiesDataKey = city.value as string;
      matrix.getDistanceMatrix({
        destinations: [{lat: position.coords.latitude, lng: position.coords.longitude}],
        origins: [{lat: cityData.lat, lng: cityData.long}],
        travelMode: google.maps.TravelMode.DRIVING
      }).then(res => {
        if (+res.rows[0].elements[0]?.distance?.value / 1000 <= +cityData.radius) {
          const selectedCity = citiesDataKey ? cities?.find(i => i.value === citiesDataKey) || null : null;
          localStorage.setItem(config.LS_CITY_KEY, citiesDataKey as string);

          if (selectedCity) {
            setSelectedCity(selectedCity?.value);
            setCurrentCity(selectedCity);
          }
        } else {
          if (index === cities?.length - 1) {
            setTimeout(() => {
              handleOpenModal();
            },1000)
          }
        }
      })
    })
  };

  const errorCallback = () => {
    handleOpenModal();
  };


  useEffect(() => {
    if (currentCity) {
      setShowSelect(true);
    }
  }, [currentCity])

  const handleOpenModal = () => {
    if (!localStorage.getItem(config.LS_CITY_KEY) && !isModalOpened) {
      setIsModalOpened(true);
      setIsOpenLNF(true);
    }
  }

  const handleCloseModal = () => {
    setIsOpenLNF(false);
    setIsLocationHandled(false);
  }

  return (
    <>
      {currentCity && <TopBarWrapper className={isMobile ? 'mobile-view' : ''}>
        {isDesktop && <Title>{currentCity && `${'Hockey Clinics in ' + currentCity?.value}`}</Title>}
        {isMobile && <FiltersButton onClick={toggleFilters}><Icon src={FiltersIcon} /> Filters {isFilterSelected && <Icon className='filters-check-icon' src={FiltersCheckIcon}/>}</FiltersButton>}
          <Label className={isMobile ? 'mobile-view': ''}>
            {isDesktop && 'Select another city'}
              <SelectWrapper className={isMobile ? 'mobile-view': ''}>
                {showSelect && <Select
                    initialValue={currentCity}
                    isSearchable={false}
                    options={cities}
                    placeholder={'Choose city'}
                    themeConfig={config.SELECT_THEME_CONFIG}
                    onOptionChange={onCityChange}
                    getOptionValue={getOptionValue}
                    getOptionLabel={getOptionLabel}
                />}
              </SelectWrapper>
          </Label>

      </TopBarWrapper>
      }
      {
        isLocationHandled && <LocationNotFound handleCloseModal={handleCloseModal} setCurrentCity={setCurrentCity} setSelectedCity={setSelectedCity} isOpen={isOpenLNF} cities={cities} />
      }
    </>
  )
}

export default TopBar;
