import React, {useState} from "react";
import {GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";
import {MapWrapper} from "./style";
import {config} from "../../config";

const containerStyle = {
  width: '100%',
  height: '340px'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

interface MapProps {
  center: {
    lat: number,
    lng: number
  }
}

const ZOOM_OPTIONS = {
  minZoom: 4,
  maxZoom: 18,
}

const GoogleMaps: React.FC<MapProps> = (props) => {
  const {center} = props;
  const [map, setMap] = useState(null)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.GOOGLE_MAP_KEY
  })

  const onLoad = React.useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <MapWrapper>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={8}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options = {ZOOM_OPTIONS}
      >
        <Marker position={center} icon={{url: require('../../assets/images/marker-icon.svg').default}} />
      </GoogleMap>
    </MapWrapper>
  ) : <></>
}

export default GoogleMaps;
