import React, {CSSProperties, useEffect, useState} from "react";
import Modal from "react-modal";
import {isMobile} from "react-device-detect";
import {Button, ModalWrapper, SubTitle, Title} from "./style";

const modalStyles: CSSProperties = {
  width: isMobile ? '95%' :'550px',
  maxHeight: '90%',
  maxWidth: '1045px',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '10px',
  boxSizing: 'border-box',
  padding: '0'
};

const styles = {
  content: modalStyles
}
interface ModalProps {
  isOpen: boolean,
  handleCloseModal: () => void
}
const ThankYouModal: React.FC<ModalProps> = (props) => {
  const {isOpen, handleCloseModal} = props;
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setModalIsOpen(isOpen);
    }
  }, [isOpen])

  const closeModal = () => {
    setModalIsOpen(false);
    handleCloseModal();
  }

  return(
    <Modal
      isOpen={modalIsOpen}
      contentLabel="Clinic Modal"
      shouldCloseOnOverlayClick={false}
      style={styles}
    >
      <ModalWrapper>
        <Title>Thank you for subscribing</Title>
        <SubTitle>
          We will be sending you regular updates as new camps and clinics become available
        </SubTitle>

        <Button onClick={() => closeModal()}>
          Close
        </Button>
      </ModalWrapper>
    </Modal>
  )
}

export default ThankYouModal;
