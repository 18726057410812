import React, {useEffect, useState} from "react";
import {Clinic, Color, Colors} from "../../models/clinic.model";
import {
  BottomContainer, ClinicCardContainer, ClinicName,
  CoachName, Duration, LocationName, Price, Row, SkillLevel, Spots, TopContainer, Years, DateInfo, TimeInfo
} from "./style";
import {config} from "../../config";
import moment from "moment";
import calendarIcon from "../../assets/images/calendar-icon.svg";
import clockIcon from "../../assets/images/clock-icon.svg";
import {Icon} from "../header/style";

interface ClinicCardProps {
  clinic: Clinic,
  colors: Colors | undefined,
  setSelectedClinic: any
}

const ClinicCard: React.FC<ClinicCardProps> = (props) => {
  const { clinic, colors, setSelectedClinic } = props;
  const [color, setColor] = useState<Color>();

  useEffect(() => {
    switch (clinic.SkillLevel) {
      case "advanced":
        setColor(colors?.advanced);
        break;
      case "intermediate":
        setColor(colors?.intermediate);
        break;
      case "beginner":
        setColor(colors?.beginner);
        break;
      default:
        setColor(config.DEFAULT_TEXT_COLOR);
    }
  }, [clinic, clinic.ClinicImage])


  const getStartDate = (): string => {
    return new Date(clinic.StartTime.seconds*1000).toString()
  }

  const getEndDate = (): string => {
    return new Date(clinic.EndTime.seconds*1000).toString()
  }

  const onClinicClick = () => {
    setSelectedClinic(clinic);
  }

  return (
    <ClinicCardContainer onClick={onClinicClick}>
      <TopContainer backgroundImage={clinic.ClinicImage} backgroundColor={clinic.colorBackground}>
        <SkillLevel textColor={color || config.DEFAULT_TEXT_COLOR}>{clinic.SkillLevelText}</SkillLevel>
        <Years>{clinic.AgeText}</Years>
        <Spots>{clinic.Spots > 0 ? `${clinic.Spots + ' spots left'}`: 'Waitlist'}</Spots>
        <Duration>{clinic.Duration}</Duration>
      </TopContainer>
      <BottomContainer>
        <Row>
          <ClinicName>{clinic.ClinicName?.id}</ClinicName>
          <Price>{clinic.Cost}</Price>
        </Row>
        <Row>
          <CoachName>{clinic.Coach?.id}</CoachName>
        </Row>
        <Row>
          <LocationName>{clinic.LocationName}</LocationName>
        </Row>
        <Row>
          <DateInfo>
            <Icon src={calendarIcon} />
            {moment(getStartDate()).format("dddd, MMM Do")}
          </DateInfo>
          <TimeInfo>
            <Icon src={clockIcon} />
            {moment(getStartDate()).format("h:mmA")}
            -
            {moment(getEndDate()).format("h:mmA")}
          </TimeInfo>
        </Row>
      </BottomContainer>
    </ClinicCardContainer>
  )
}

export default ClinicCard;
