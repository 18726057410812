import styled from "styled-components";
import { Button } from "../header/style";

export const FilterWrapper = styled.div`
  display: flex;
  padding: 25px 20px 30px;
  gap: 10px;
  border-right: 1px solid #D1D5DB;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  background-color: #ffffff;
  
  &.mobile-view {
    width: 100%;
    position: fixed;
    height: 0vh;
    bottom: 0;
    z-index: 10;
    overflow: auto;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    transition: all .3s ease;
    padding: 0;
    
    &.show {
      height: calc(100% - 50px);
      transition: all .3s ease;
      padding: 25px 20px 30px;
    }
  }

  .ads-container {
    width: 100%;
    height: 100px;
    
    .adsbygoogle {
      padding-top: 20px !important;
    }
  }
  

  @media (min-width: 991px) {
    width: 21%;
  }
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: #111827;
  padding-bottom: 5px;
  
  &.mobile-view {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid #EFEFEF;
    padding: 0 0 12px;
  }
`

export const Label = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #111827;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  
  .flatpickr-input {
    width: 100%;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 8px 13px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4B5563;
    box-sizing: border-box;
  }
`

export const SelectContainer = styled.div<{isShort?: boolean}>`
  width: ${props => props.isShort ? ' 50%' : '100%'};
`

export const AgeWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`

export const CheckBoxContainer = styled.div`
  label {
    padding-left: 24px;
    position: relative;
  }
  
  input[type='checkbox'] {
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    margin: 1px 0 0;
    
    &:before {
      content: '';
      width: 16px;
      height: 16px;
      display: block;
      border: 1px solid #D1D5DB;
      border-radius: 4px;
    }
    
    &:checked:after {
      content: "";
      display: block;
      width: 5px;
      height: 10px;
      border: solid black;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 2px;
      left: 6px;
    }
  }
  
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #111827;
`

export const ClearButton = styled(Button)`
  width: 100%;
  box-sizing: border-box;
  background: #4B5563;
  padding: 10px 12px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
`

export const ApplyButton = styled(Button)`
  width: 100%;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 12px;
  margin-top: 20px;
  background: #E9072B;
  color: #fff;
`

export const Close = styled.img`
  margin-left: auto;
`
