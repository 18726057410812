import { ButtonNext } from "pure-react-carousel";
import styled from "styled-components";
import {Color} from "../../../models/clinic.model";

export const Close = styled.img`
  position: absolute;
  top: 17.5px;
  right: 17.5px;
  width: 15px;
  height: 15px;
  cursor: pointer;
`

export const Wrapper = styled.div`
  padding: 40px 10px 30px;
  display: flex;
  flex-wrap: wrap;
  
  &.mobile-view {
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  
  @media (min-width: 768px) {
    padding: 60px 20px 20px;
  }
`

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  justify-content: flex-start;
  
  @media (min-width: 768px) {
    width: 35%;
  }
`

export const ClinicLogo = styled.img<{ backgroundColor: Color }>`
  background-color: rgb(${props => props.backgroundColor.R}, ${props => props.backgroundColor.G}, ${props => props.backgroundColor.B});
  border-radius: 15px;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  height: 276px;
  object-fit: contain;
`

export const Button = styled.a`
  background: #E9072B;
  border-radius: 8px;
  padding: 8px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
  text-decoration: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
`

export const Price = styled.div`
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
  color: #111827;
  padding: 15px 0 10px;
  text-align: center;
  
  @media (min-width: 991px) {
    padding: 0;
    text-align: left;
  }
`

export const RightSide = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  
  @media (min-width: 768px) {
    width: 65%;
    padding-left: 30px;
  }
`

export const ClinicName = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #111827;
  position: relative;
  
  @media (min-width: 991px) {
    font-weight: 700;
    font-size: 28px;
    line-height: 24px;
  }
`
export const Spots = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  color: #fff;
  background: #6B99D1;
  border-radius: 15px;
  padding: 0 20.2718px;
  position: absolute;
  right: 0;
  top: -5px;
`

export const MainInfo = styled.div`
  display: flex;
  gap: 5px;
  padding: 11px 0 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #111827;
  
  @media (min-width: 991px) {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    padding: 25px 0;
  }
`

export const Icon = styled.img`
`

export const Dot = styled.div`
  width: 24px;
  display: flex;
  justify-content: center;
  
  &:after {
    content: "•";
    display: block;
    width: 4px;
    height: 4px;
    margin-left: -2px;
  }
`

export const Space= styled.div`
  padding: 0 5px;
`

export const Description = styled.div`
  input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  input:focus ~ label {
    outline: none;
  }

  input:checked + span {
    -webkit-line-clamp: unset;
  }
  
  label {
    padding-top: 10px;
    padding-bottom: 5px;
    width: 100%;
    display: block;
    text-align: end;
  }
  
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #1F2937;
    max-height: 180px;
    overflow: auto;
    margin-bottom: 25px;
    display: block;
   
    &.mobile-view {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      margin-bottom: 0;
      overflow: hidden;
      max-height: unset;
    }

    @media (min-width: 991px) {
      font-size: 20px;
      line-height: 30px;
    }
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #111827;
  padding-bottom: 15px;
  
  span.mobile-view {
    display: block;
    width: 100%;
  }
  
  @media (min-width: 991px) {
    font-size: 20px;
    line-height: 22px;
  }
`

export const IconCT = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

export const CoachName = styled(Title)`
  span {
    font-weight: 400;
    color: #007AFF;
    cursor: pointer;
  }

  &.mobile-view {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Place = styled(Title)`
  &.mobile-view {
    flex-direction: column;
    align-items: flex-start;
    order: 1;
  }
`

export const When = styled(Title)`
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #454D57;
    display: flex;
    
    div {
      padding-right: 5px;
      
      &:after {
        color: #454D57;
      }
    }
    
    @media (min-width: 991px) {
      font-size: 20px;
      line-height: 22px;
    }
  }

  &.mobile-view {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const CoachRating = styled.div<{ width: number }>`
  display: flex;
  padding: 25px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
  
  @media (min-width: 991px) {
    font-size: 20px;
    line-height: 22px;
  }

  .star {
    width: 25px;
    position: relative;
    color: #bdbdbd;
  }

  .rating {
    width: ${props => props.width}%;
    overflow: hidden;
    white-space: nowrap;
  }

  .rating span {
    font-size: 25px;
    white-space: nowrap;
    overflow: hidden;
    color: gold;
  }

  .rating span:before {
    content: "\\2606";
    position: absolute;
    color: #bdbdbd;
    z-index: -1;
  }
`

export const CoachClinics = styled.div`
  width: 100%;
`

export const BackButton = styled.div`
  position: absolute;
  top: 17.5px;
  left: 20px;
  cursor: pointer;
  padding: 5px;
  
  &:before {
    content: '';
    display: block;
    height: 9px;
    width: 9px;
    border-bottom: 2px solid rgb(17, 24, 39);
    border-left: 2px solid rgb(17, 24, 39);
    transform: rotate(45deg);
  }
`

export const CoachReviews = styled.div`
  width: 100%;
  padding-top: 15px;
  
  @media (min-width: 991px) {
    padding-top: 0;
  }
`

export const SeeMore = styled(ButtonNext)`
  border: none;
  background-color: transparent;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #E9072B;
  display: flex;
  align-items: center;
  gap: 14px;

  &:after {
    content: '';
    display: block;
    height: 9px;
    width: 9px;
    border-top: 2px solid #E9072B;
    border-right: 2px solid #E9072B;
    transform: rotate(45deg);
  }
`

export const CarouselTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #111827;
  padding-bottom: 15px;
  
  @media (min-width: 991px) {
    padding-bottom: 0;
    font-size: 22px;
    line-height: 24px;
  }
`

export const CarouselHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media (min-width: 991px) {
    padding-top: 25px;
  }
`
