import styled from "styled-components";

export const ReviewCard = styled.div<{ width: number }>`
  padding: 0 10px 10px 0;
  
  @media (min-width: 991px) {
    padding: 15px 10px;
  }

  .star {
    width: 16px;
    position: relative;
    color: #bdbdbd;
    margin-left: auto;
  }

  .rating {
    width: ${props => props.width}%;
    overflow: hidden;
    white-space: nowrap;
  }

  .rating span {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    color: gold;
  }

  .rating span:before {
    content: "\\2606";
    position: absolute;
    color: #bdbdbd;
    z-index: -1;
  }
`
export const Inner = styled.div`
  padding: 10px;
  border: 1px solid #E1D2D2;
  border-radius: 5px;
`

export const TopRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 7px;
  
  .small-star {
    width: 16px;
    height: 16px;
    margin-left: auto;
  }
`

export const Name = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
`

export const ReviewDate = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #587591;
`

export const ReviewText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #111827;
  height: 80px;
  overflow: auto;
`

export const Rating = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #1F1F1F;
  margin-left: -7px;
`
