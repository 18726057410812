import styled from "styled-components";

export const Wrapper = styled.div`
  @media (min-width: 991px) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  
  &.mobile-view {
    overflow: hidden;
  }
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 145px);
  flex-wrap: wrap; 
  
  @media (min-width: 991px) {
    height: calc(100vh - 183px);
    flex-wrap: nowrap;
  }
`

export const ClinicNotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding-top: 30px;
  font-weight: 500;
  font-size: 18px;
`
