import React, {useEffect, useState} from "react";
import {Clinic, Coach, Color, Review} from "../../../../models/clinic.model";
import {
  BackButton, CarouselHeader, CarouselTitle,
  ClinicLogo,
  ClinicName,
  CoachClinics,
  CoachRating,
  CoachReviews,
  Description,
  Dot,
  LeftSide,
  RightSide, SeeMore
} from "../style";
import {db} from "../../../../firebase";
import { DocumentReference } from "firebase/firestore"
import ClinicCardSmall from "./clinic-small-card/clinic-card-small";
import {CarouselProvider, Slider, Slide} from "pure-react-carousel";
import 'pure-react-carousel/dist/react-carousel.es.css';
import CoachReview from "./coach-review/coach-review";
import {isDesktop, isMobile, isTablet} from "react-device-detect";

interface CoachProps {
  coach: Coach,
  coachRef: DocumentReference,
  clinicImage?: string,
  backgroundColor: Color,
  handleBack: () => void
}

const AboutCoach: React.FC<CoachProps> = (props) => {
  const {coach, clinicImage, backgroundColor, coachRef, handleBack} = props;
  const [clinics, setClinics] = useState<Array<Clinic>>([]);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);

  const calculateRating = () => {
    return (coach.Reviews.reduce((n, {points}) => n + points, 0) / coach.Reviews.length).toFixed(1)
  }

  useEffect(() => {
    fetchCoachClinics().then();
  }, [])

  const fetchCoachClinics = async () => {
    const clinics: Array<Clinic> = [];
    db.collection('Toronto')
      .doc('Hockey')
      .collection('Clinic List')
      .where("Coach", '==', coachRef).where("StartTime", ">=", new Date())
      .orderBy("StartTime").get().then(async (querySnapshot) => {
          await querySnapshot.docs
            .map((doc: { data: () => any; id: any; }) => {
              const clinic = doc.data();
              clinic.ClinicName.get().then((doc:  { data: () => any; id: any; }) => {
                return clinic.ClinicName = {...doc.data(), id: doc.id};
              })
              clinics.push({...clinic, id: doc.id});
              setClinics(clinics);
            });
      setIsLoad(true);
    });
  }

  return (
    <>
      <BackButton onClick={handleBack}/>
      <LeftSide>
        {isMobile && !isTablet && <ClinicName>{coach.id}</ClinicName>}
        <ClinicLogo src={clinicImage} backgroundColor={backgroundColor}/>
      </LeftSide>
      <RightSide>
        {(isDesktop || isTablet) &&
          <ClinicName>{coach.id}</ClinicName>
        }
        <CoachRating width={100/5*(+calculateRating())}>
          <div className="star">
            <div className="rating">
              <span>&#x2605;</span>
            </div>
          </div>
          {calculateRating()}
          <Dot/>
          {coach.Reviews.length} Ratings
        </CoachRating>
        <Description>
          <input onChange={() => setShowMore(!showMore)} type="checkbox" id="expanded" />
          <span className={isMobile ? 'mobile-view' : ''}>{coach.CoachDescription}</span>
          {isMobile && <label htmlFor="expanded" role="button"> {showMore ? 'Read less' : 'Read more'}</label>}
        </Description>

      </RightSide>
      {!!clinics.length && <CoachClinics>
        {isLoad && <CarouselProvider
            naturalSlideWidth={236}
            naturalSlideHeight={180}
            visibleSlides={isMobile && !isTablet ? 1.5 : 4}
            dragEnabled={false}
            infinite={true}
            isIntrinsicHeight={true}
            totalSlides={clinics.length}
        >
            <CarouselHeader>
                <CarouselTitle>Upcoming Clinics and Camps</CarouselTitle>
              {!isMobile && !isTablet && clinics.length > 4 && <SeeMore>See more</SeeMore>}
            </CarouselHeader>
            <Slider>
              {!!clinics.length && clinics.map((clinic: Clinic, index) => <Slide className='slide' key={index}
                                                                                 index={index}><ClinicCardSmall
                clinic={clinic}/></Slide>)}
            </Slider>
        </CarouselProvider>}
      </CoachClinics>
      }

      {!!coach.Reviews.length && !!coach.Reviews.filter(r => r.text.length).length && <CoachReviews>
        <CarouselProvider
          naturalSlideWidth={492}
          naturalSlideHeight={140}
          visibleSlides={isMobile && !isTablet ? 1.5 : 2}
          dragEnabled={false}
          infinite={true}
          isIntrinsicHeight={true}
          totalSlides={coach.Reviews.length}
        >
          <CarouselHeader>
            <CarouselTitle>Reviews</CarouselTitle>
            {!isMobile && !isTablet && coach.Reviews.filter(r => r.text.length).length > 2 && <SeeMore>See more</SeeMore>}
          </CarouselHeader>
          <Slider>
            {!!coach.Reviews.length && coach.Reviews.filter(r => r.text.length).map((review: Review, index) => <Slide className='slide' key={index} index={index}><CoachReview review={review} /></Slide>)}
          </Slider>
        </CarouselProvider>
      </CoachReviews>
      }
    </>
  )
}

export default AboutCoach;
