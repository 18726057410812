import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  
  @media (min-width: 991px) {
    margin-left: auto;
  }
`

export const ShowControl = styled.div`
  span.control {
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #E9072B;
  }
`

export const DatesContainer = styled.div`
  position: absolute;
  top: 30px;
  right: -5px;
  width: 550px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px 20px;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 999999;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  color: #454D57;
  
  &.mobile-view {
    position: relative;
    top: unset;
    right: unset;
    padding: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    gap: 7px;
    width: 100%;
  }
`
