import React, {useEffect, useState} from "react";
import ClinicList from "../../components/clinik-list/clinic-list";
import {ClinicNotFound, Container, Wrapper} from "./style";
import Filters from "../../components/filters/filters";
import {AboutClinic, Clinic} from "../../models/clinic.model";
import {db, storage} from "../../firebase";
import { Grid } from 'react-loader-spinner'
import TopBar from "../../components/top-bar/top-bar";
import { Button } from "../../components/header/style";
import {isMobile} from "react-device-detect";

export type Option = {
  value: number | string;
  label: number | string;
};

const Home: React.FC = () => {
  const [clinics, setClinics] = useState<Array<Clinic>>([]);
  const [allClinics, setAllClinics] = useState<Array<Clinic>>([]);
  const [showPreload, setIsShowPreload] = useState<boolean>(true);
  const [spotsAvailable, setSpotsAvailable] = useState<boolean>(false);
  const [isShowClearButton, setIsShowClearButton] = useState<boolean>(false);
  const [selectedSkillLevel, setSelectedSkillLevel] = useState<Option | null>(null);
  const [selectedSessionType, setSessionType] = useState<Option | null>(null);
  const [selectedMinOption, setMinSelectedOption] = useState<Option[] | null>(null);
  const [selectedCoach, setSelectedCoach] = useState<Option | null>(null);
  const [selectDate, onSelectDateChange] = useState<any>([null, null]);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [isShowFilters, setIsShowFilters] = useState<boolean>(false);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  const [clinicImages, setClinicImages] = useState<{ name: any; url: string; }[]>([]);
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const fetchClinics = async () => {
    const clinicsData: Array<Clinic> = []
    if (selectedCity != null) {
      await db.collection(selectedCity)
        .doc('Hockey')
        .collection('Clinic List')
        .where("StartTime", '>=', new Date())
        .orderBy('StartTime')
        .get().then(async snapshot => {
          if (snapshot.docs.length) {
            snapshot.docs.forEach((doc: { data: () => any; id: any; }, index) => {
              const clinic = {...doc.data(), id: doc.id};
              clinic.Coach.get().then(async (snap: { data: () => any; id: string; }) => {
                clinic.CoachData = {...snap.data(), id: snap.id};

                if (clinic.CoachData?.imageURL) {
                  if (clinicImages.find(i => i.name === clinic.CoachData?.imageURL)) {
                    clinicImages.find(i => {
                      if ( i.name === clinic.CoachData?.imageURL) {
                        clinic.ClinicImage = i.url
                      }
                    })
                  } else {
                    storage.refFromURL(clinic.CoachData?.imageURL as string).getDownloadURL().then(url => {
                      const image = {
                        name: clinic.CoachData?.imageURL,
                        url: url
                      }
                      const images = [...clinicImages, image];
                      setClinicImages(images);
                      clinic.ClinicImage = url;
                    });
                  }
                }

                await db.collection(selectedCity)
                  .doc('Hockey')
                  .collection('Coaches')
                  .doc(snap.id).collection('Clinics').get()
                  .then(async (querySnapshot: { docs: { data: () => any; id: any; }[]; }) => await querySnapshot.docs.map((doc: { data: () => any; id: any; }) => ({
                    ...doc.data(),
                    id: doc.id
                  })))
                  .then((res: AboutClinic[]) => {
                    clinic.CoachData.Clinics = res as AboutClinic[];

                    clinic.ClinicName.get().then((snap: { data: () => any; id: string; }) => {
                      clinic.ClinicName = {...snap.data(), id: snap.id};
                      clinicsData.push(clinic as Clinic);
                      if (snapshot.size === clinicsData.length) {
                        setIsShowPreload(false);
                        clinicsData.sort((a, b) => a.StartTime.seconds - b.StartTime.seconds);
                        setClinics(clinicsData);
                        setAllClinics(clinicsData);
                      }
                    });
                  });
              });
            })
          } else {
            setIsShowPreload(false);
            setClinics([]);
            setAllClinics([]);
            setShowMessage(true);
          }
        })
    }
  }

  useEffect(()=>{
    if (selectedCity) {
      setIsNotFound(true);
      setShowMessage(false);
      setIsShowPreload(true)
      setIsShowClearButton(false)
      fetchClinics().then();
    }
  }, [selectedCity])

  useEffect(()=>{
    if (!showPreload) {
      fetchFilteredClinics().then();
    }
  }, [spotsAvailable, selectedSkillLevel, selectDate, selectedSessionType, selectedMinOption, selectedCoach]);

  const fetchFilteredClinics = async () => {
    let filteredClinic: Clinic[] = allClinics;

    if (selectedSkillLevel) {
      filteredClinic = filteredClinic.filter(i => i.SkillLevel === selectedSkillLevel.value);
    }
    if (spotsAvailable) {
      filteredClinic = filteredClinic.filter(i => i.Spots > 0);
    }
    if (selectedSessionType) {
      filteredClinic = filteredClinic.filter(i => i.Type === selectedSessionType.value);
    }
    if (selectedMinOption?.length) {
      filteredClinic = filteredClinic.filter(i => {
        if (selectedMinOption.some(date => +i.AgeMin >= +date.value && +date.value >= +i.AgeMax)) {
          return i;
        }
      });
    }
    if (selectedCoach) {
      filteredClinic = filteredClinic.filter(i => i.CoachData?.id === selectedCoach.value);
    }

    if ((selectDate as []).every(i => !!i)) {
      const minDate = Date.parse(selectDate[0])/1000;
      const maxDate = Date.parse(selectDate[1])/1000 === minDate ? Date.parse(selectDate[1])/1000 + 86400 : Date.parse(selectDate[1])/1000;
      filteredClinic = filteredClinic.filter(i => i.StartTime.seconds >= minDate && i.StartTime.seconds <= maxDate);
    }

    setIsShowClearButton(isFilterSelected(filteredClinic.length))
    setClinics(filteredClinic);
  }

  const isFilterSelected = (length: number) => {
    return allClinics.length !== length || spotsAvailable;
  }

  const clearFilter = () => {
    setClinics(allClinics);
    onSelectDateChange([null, null]);
    setMinSelectedOption(null);
    setSpotsAvailable(false);
    if (isNotFound) {
      setIsNotFound(false);
    }
  }

  const toggleFilters = () => {
    setIsShowFilters(!isShowFilters);
  }

  return (
    <Wrapper className={isMobile && isShowFilters ? 'mobile-view' : ''}>
      <TopBar setSelectedCity={setSelectedCity} toggleFilters={toggleFilters} isFilterSelected={isFilterSelected(clinics.length)}/>
      <Container>
        {selectedCity && <Filters
            setSpotsAvailable={setSpotsAvailable}
            setSessionType={setSessionType}
            setMinSelectedOption={setMinSelectedOption}
            setSelectedSkillLevel={setSelectedSkillLevel}
            setSelectedCoach={setSelectedCoach}
            selectDate={selectDate}
            spotsAvailable={spotsAvailable}
            clearFilter={clearFilter}
            selectedCity={selectedCity}
            isShowClearButton={isShowClearButton}
            onSelectDateChange={onSelectDateChange}
            isShowFilters={isShowFilters}
            toggleFilters={toggleFilters}
            isNotFound={isNotFound}
        />}

        {!showPreload && !!clinics.length && <ClinicList clinics={clinics}/> }
        {!showPreload && !clinics.length && !showMessage &&
          <ClinicNotFound>
              Clinics not found
              <Button onClick={() => setIsNotFound(true)}>Clear filters</Button>
          </ClinicNotFound>
        }
        {
          !clinics.length && showMessage &&
          <ClinicNotFound>
              We are sorry.  There are no Clinics available at this time for {selectedCity}.
          </ClinicNotFound>
        }
        {
          showPreload && <Grid
              height="80"
              width="80"
              color="#587591"
              ariaLabel="grid-loading"
              radius="12.5"
              wrapperStyle={{}}
              wrapperClass="loader"
              visible={true}
          />
        }
      </Container>
    </Wrapper>
  )
}

export default Home;
