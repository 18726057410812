import React, {useEffect, useState} from "react";
import { isMobile } from "react-device-detect";
import {Button, FormWrapper} from "./style";
import ThankYouModal from "../modals/thank-you/thank-you";

interface MailchimpFormProps {
  status: any,
  onValidated: (arg:{EMAIL: string}) => any,
  closeHero: () => void
}

export const MailchimpForm = ({ status, onValidated, closeHero }: MailchimpFormProps) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  let email: HTMLInputElement | null;

  const submit = () => {
    setShowError(false);
    if (email && email.value.indexOf("@") === -1) {
      setShowError(true);
    } else {
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
    }
  }

  useEffect(() => {
    if (status === "success") {
      localStorage.setItem('subscribed', '1');
      setIsOpenModal(true)
    }
  }, [status])

  const handleCloseModal = () => {
    setIsOpenModal(false);
    closeHero();
  }

  return (
    <FormWrapper className={isMobile ? 'mobile-view' : ''}>
      <input
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        ref={node => (email = node)}
        type="email"
        placeholder="Your email address"
      />

      {(status === "error" || showError) && <div style={{ color: "white" }} dangerouslySetInnerHTML={{__html: 'Email address is invalid'}}/>}

      <Button onClick={()=>submit()} className={isMobile ? 'mobile-view' : ''}>
        I’m in!
      </Button>
      <ThankYouModal isOpen={isOpenModal} handleCloseModal={handleCloseModal} />
    </FormWrapper>
  );
};
